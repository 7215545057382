import { Libraries } from "@react-google-maps/api";

export const apiUrl = (process.env.API_URI || process.env.NEXT_PUBLIC_API_URI)!;

export const siteUrl = process.env.SITE_URL || window?.location?.origin;

export const channelSlug = process.env.NEXT_PUBLIC_SALEOR_CHANNEL_SLUG!;

export const domain = process.env.NEXT_PUBLIC_DOMAIN;

export const keycloakRealm = process.env.NEXT_PUBLIC_KEYCLOAK_REALM;

export const ssrMode = typeof window === "undefined";

export const productsPerPage = parseInt(
  process.env.PRODUCTS_PER_PAGE || "20",
  10
);

export const imageLoaderUri = process.env.NEXT_PUBLIC_IMAGE_LOADER_URI;
export const klaviyoPublicKey = process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_KEY;
export const klaviyoSubscribeListId =
  process.env.NEXT_PUBLIC_KLAVIYO_SUBSCRIBE_LIST_ID;

export const lenderRatingOptions = [{ name: "★★★★★ only", slug: "5" }];

// payment methods
export const afterPay = "afterpay_clearpay";

export const stripePublicKey = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;
export const pusherAppKey = process.env.NEXT_PUBLIC_PUSHER_APP_KEY;
export const pusherCluster = process.env.NEXT_PUBLIC_PUSHER_CLUSTER;

export const Z_INDEX_OVERLAY = 1000000;

export const gatewaySvcUri = process.env?.NEXT_PUBLIC_GATEWAY_SVC_URI || "";

export const cfClientId = process.env?.CF_CLIENT_ID || "";
export const cfClientSecret = process.env?.CF_CLIENT_SECRET || "";
export const tikTokEventSourceId =
  process.env?.TIK_TOK_EVENT_SOURCE_ID ||
  process.env?.NEXT_PUBLIC_TIK_TOK_EVENT_SOURCE_ID;

export const nextCFClientId = process.env?.NEXT_PUBLIC_CF_CLIENT_ID || "";
export const nextCFClientSecret =
  process.env?.NEXT_PUBLIC_CF_CLIENT_SECRET || "";

export const googleMapsLibraries: Libraries = ["places", "geometry"];
export const nextPublicGoogleMapsApiKey =
  process.env?.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "";

export const isDev =
  domain?.includes("staging") || domain?.includes("localhost");
